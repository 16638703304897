.text-center {
  text-align: center;
}

.calendar-container {
  position: relative;
  width: 90vw;
  padding-top: 10px;
  padding-inline: 10px;
  min-height: 80vh;
  max-width: 800px;
  overflow: hidden;

}

.calendar-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}