.home-page-text {
  max-width: 600px;
}

.text-center>ul {
  list-style-type: none;
  /* Remove bullet points */
  padding: 0;
  /* Remove default padding */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 800px;
}

@media (max-width:800px) {
  .text-center>ul {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

@media (max-width:480px) {
  .text-center>ul {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

.text-center>ul>li {
  margin: 10px 0;
  /* Add some spacing between links */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.text-center>ul>li>a {
  color: #007bff;
  /* Link color */
  text-decoration: none;
  /* Remove underline */
}

.text-center>ul>li>a:hover {
  text-decoration: underline;
  /* Underline on hover */
}

.text-center {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.zodiacts {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
}

#zodiacts-img {
  width: 50%;
}