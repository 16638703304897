.admin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Optional: space between the images */
  align-items: center;
  gap: 20px;
}

.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

input[type="password"] {
  padding: 10px;
  margin: 10px;
  width: 200px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}