.navbar {
  background-color: #333;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  width: 800px;
}

@media (max-width:800px) {
  .navbar-container {
    width: 100vw;
  }
}

/* 
@media (max-width:520px) {
  .navbar-container .link-container {
    display: none;
  }

  .navbar-container .dropdown-button-container {
    display: inline !important;
  }
} */

.navbar-logo {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding-inline: 1rem;
  gap: 1rem;
}

.navbar-container ul {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0 1rem;
  gap: 1rem;

}

.navbar-container li {
  display: inline;
}

.navbar-container a {
  color: white;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  transition: background-color 0.3s;
  border-radius: 5px;
  vertical-align: middle;
  display: inline-block;
}

.navbar-container a:hover {
  background-color: #575757;
}

/* .navbar-container .dropdown-button-container {
  display: none;
} */

.navbar-container button {
  /* display: none; */
  all: unset;
  color: white;
  text-decoration: none;
  padding: 0.5rem;
  vertical-align: middle;
  display: inline-block;
}

.navbar .icon-container {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.navbar .icon {
  margin-right: 0.25rem;
  /* Add spacing if needed */
}

.dropdown-menu {
  margin-top: 1rem;
  border-top: 1px solid black;
}

.dropdown-menu ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0;
  padding: 0 0 0 0;
  /* gap: 1rem; */
}

.dropdown-menu li {
  display: inline;
  padding-block: 0.5rem;
}

.dropdown-menu a {
  color: white;
  text-decoration: none;
  padding: 0rem;
  transition: background-color 0.3s;
  border-radius: 5px;
  vertical-align: middle;
  display: inline-block;
}